import React from "react";
import "./App.css";
import { useState, useEffect } from "react";
import Access from "./components/access/access";
import Authentication from "./components/auth/authentication";
import axios from "axios";

axios.defaults.baseURL = "http://avmqa.valuaciones.cl/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  let tokenStr = sessionStorage.getItem("token");
  config.headers.Authorization = tokenStr ? `Bearer ${tokenStr}` : '';
  return config;
})
function App() {
  let [status, setStatus] = useState(false);
  let [checkHidden, setCheckHidden] = useState(
    sessionStorage.getItem("sessionHidden")
  );
  useEffect(() => {
    if (checkHidden === "1") {
      setCheckHidden(true);
    } else {
      setCheckHidden(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!status ? (
        <>
          <button
            variant="warning"
            onClick={(e) => {
              setStatus(!status);
            }}
            hidden={checkHidden}
            style={{ position: "fixed", right: "5vw", top: "1vh" }}
          >
            <i className="fas fa-lock"></i>
          </button>
          <Access />
        </>
      ) : (
        <>
          <>
            <button
              variant="warning"
              onClick={(e) => setStatus(!status)}
              hidden={checkHidden}
              style={{ position: "fixed", right: "5vw", top: "1vh" }}
            >
              <i className="fas fa-user"></i>
            </button>
          </>
          <Authentication/>
        </>
      )}
    </>
  );
}

export default App;

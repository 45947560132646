import React, { useState, useEffect } from "react";
import APIRoute from "../routersAPI/routes.json";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  FormGroup,
  TextField,
  Button,
  Grid,
  Alert,
  Typography,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import { Container } from "@mui/system";
import { IoIosArrowBack } from "react-icons/io";

export default function EditClient() {
  const { id } = useParams();
  const [rutClient, setRutClient] = useState("");
  const [nameClient, setNameClient] = useState("");
  const [emailClient, setEmailClient] = useState("");
  const [phoneClient, setPhoneClient] = useState("");
  const [validationError, setValidationError] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    fetchClient();
  }, []);

  const fetchClient = async () => {
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .get(APIRoute.client + "/" + id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setLoading(true);
        if (typeof data == "object") {
          setRutClient(data["rut_client"]);
          setNameClient(data["name_client"]);
          setEmailClient(data["email_client"]);
          setPhoneClient(data["phone_client"]);
        } else {
          setValidationError("El cliente no se encuentra o fue eliminado");
        }
      })
      .catch(() => {
        Swal.fire({
          text: "error cargando cliente",
          icon: "error",
        });
      });
  };
  const updateClient = async (e) => {
    setValidationError(0);
    setShow(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("rut", rutClient);
    formData.append("name", nameClient);
    formData.append("phone", phoneClient);
    formData.append("email", emailClient);
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.editClientPost + id, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
        params: {
          _method: "patch",
        },
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: "cliente editado con éxito",
        });
        setShow(false);
      })
      .catch(({ response }) => {
  if (response.status === 422) {
    const errors = Object.values(response.data.message);
    setValidationError(errors);
  } else {
    setValidationError([response.data.message.phone, response.data.message.email]);
  }
});
    setShow(false);
  };
  return (
    <Box component="main" sx={{ p: 1, my: 2 }}>
      <Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Container>
                <Typography
                  className="text-center align-items-center"
                  variant="h6"
                >
                  Actualizar Datos Cliente
                </Typography>
                <hr />
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  {loading ? (
                    <FormGroup>
                      <Grid
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        container
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        {Object.keys(validationError).length > 0 && (
                          <Grid direction="row">
                            <Alert variant="filled" severity="error">
                              {validationError}
                            </Alert>
                          </Grid>
                        )}
                        <Typography>Rut cliente</Typography>
                        <TextField
                          error={rutClient.length === 0 ? true : false}
                          helperText={!rutClient.length ? "Rut requerido" : ""}
                          sx={{ minWidth: 300, my: 2 }}
                          placeholder="Ingrese rut del cliente"
                          value={rutClient}
                          required
                          onChange={(event) => {
                            setRutClient(event.target.value);
                          }}
                        />
                        <Typography>Nombre cliente</Typography>
                        <TextField
                          error={nameClient.length === 0 ? true : false}
                          helperText={
                            !nameClient.length ? "Nombre requerido" : ""
                          }
                          sx={{ minWidth: 300, my: 2 }}
                          placeholder="Ingrese nombre del cliente"
                          value={nameClient}
                          required
                          onChange={(event) => {
                            setNameClient(event.target.value);
                          }}
                        />
                        <Typography>Correo cliente</Typography>
                        <TextField
                          error={emailClient.length === 0 ? true : false}
                          helperText={
                            !emailClient.length ? "Correo requerido" : ""
                          }
                          sx={{ minWidth: 300, my: 2 }}
                          placeholder="Ingrese email del cliente"
                          value={emailClient}
                          required
                          onChange={(event) => {
                            setEmailClient(event.target.value);
                          }}
                        />
                        <Typography className="text-center align-items-center">
                          Teléfono cliente
                        </Typography>
                        <Grid sx={{ my: 2 }}>
                          <TextField
                            sx={{ width: 60, mx: 2 }}
                            disabled
                            value="+ 56"
                          ></TextField>
                          <TextField
                            error={phoneClient.length === 0 ? true : false}
                            helperText={
                              !phoneClient.length ? "Teléfono requerido" : ""
                            }
                            sx={{ minWidth: 150 }}
                            placeholder="Ej: 98787678"
                            value={phoneClient}
                            required
                            onChange={(event) => {
                              setPhoneClient(event.target.value);
                            }}
                          />
                        </Grid>
                        <Grid>
                          <Link to={"/"} color="success">
                            <Button
                              type="submit"
                              sx={{ m: 3 }}
                              variant="contained"
                              title="Volver"
                            >
                              <Typography variant="h6">
                                <IoIosArrowBack style={{ fontSize: "25px" }} />
                                <Typography sx={{ mx: 1 }} variant>
                                  Volver
                                </Typography>
                              </Typography>
                            </Button>
                          </Link>
                          {show ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              title="Ingresar"
                              variant="contained"
                              color="success"
                              onClick={updateClient}
                            >
                              <Typography variant="h6">Actualizar</Typography>
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </FormGroup>
                  ) : (
                    <CircularProgress className="text-center align-items-center" />
                  )}
                </Grid>
              </Container>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Container sx={{ minWidth: 500, my: 2 }}>
                <Typography
                  className="text-center align-items-center"
                  variant="h6"
                >
                  Actualizar Datos Cliente
                </Typography>
                <hr />
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  {loading ? (
                    <FormGroup>
                      <Grid
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        container
                        sx={{ my: 2 }}
                      >
                        {Object.keys(validationError).length > 0 && (
                          <Grid direction="row">
                            <Alert variant="filled" severity="error">
                              {validationError}
                            </Alert>
                          </Grid>
                        )}
                        <Typography>Rut cliente</Typography>
                        <TextField
                          error={rutClient.length === 0 ? true : false}
                          helperText={!rutClient.length ? "Rut requerido" : ""}
                          sx={{ minWidth: 350, my: 2 }}
                          placeholder="Ingrese rut del cliente"
                          value={rutClient}
                          required
                          onChange={(event) => {
                            setRutClient(event.target.value);
                          }}
                        />
                        <Typography>Nombre cliente</Typography>
                        <TextField
                          error={nameClient.length === 0 ? true : false}
                          helperText={
                            !nameClient.length ? "Nombre requerido" : ""
                          }
                          sx={{ minWidth: 350, my: 2 }}
                          placeholder="Ingrese nombre del cliente"
                          value={nameClient}
                          required
                          onChange={(event) => {
                            setNameClient(event.target.value);
                          }}
                        />
                        <Typography>Correo cliente</Typography>
                        <TextField
                          error={emailClient.length === 0 ? true : false}
                          helperText={
                            !emailClient.length ? "Correo requerido" : ""
                          }
                          sx={{ minWidth: 350, my: 2 }}
                          placeholder="Ingrese email del cliente"
                          value={emailClient}
                          required
                          onChange={(event) => {
                            setEmailClient(event.target.value);
                          }}
                        />
                        <Typography className="text-center align-items-center">
                          Teléfono cliente
                        </Typography>
                        <Grid sx={{ my: 2 }}>
                          <TextField
                            sx={{ width: 60, mx: 2 }}
                            disabled
                            value="+ 56"
                          ></TextField>
                          <TextField
                            error={phoneClient.length === 0 ? true : false}
                            helperText={
                              !phoneClient.length ? "Teléfono requerido" : ""
                            }
                            sx={{ minWidth: 150 }}
                            placeholder="Ej: 98787678"
                            value={phoneClient}
                            required
                            onChange={(event) => {
                              setPhoneClient(event.target.value);
                            }}
                          />
                        </Grid>
                        <Grid>
                          <Link to={"/"} color="success">
                            <Button
                              type="submit"
                              sx={{ m: 3 }}
                              variant="contained"
                              title="Volver"
                            >
                              <Typography variant="h6">
                                <IoIosArrowBack style={{ fontSize: "25px" }} />
                                <Typography sx={{ mx: 1 }} variant>
                                  Volver
                                </Typography>
                              </Typography>
                            </Button>
                          </Link>
                          {show ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              title="Ingresar"
                              variant="contained"
                              color="success"
                              onClick={updateClient}
                            >
                              <Typography variant="h6">Actualizar</Typography>
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </FormGroup>
                  ) : (
                    <CircularProgress />
                  )}
                </Grid>
              </Container>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

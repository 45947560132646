import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import {
  Grid,
  Button,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Container } from "@mui/system";
import { HiDocumentDownload } from "react-icons/hi";
import { RiUpload2Fill } from "react-icons/ri";

import Swal from "sweetalert2";

export default function AppreciationAsset() {
  const [idClient] = useState(sessionStorage.getItem("idUser"));
  var [idAdministrator] = useState(sessionStorage.getItem("id"));
  const [counter, setCounter] = useState(0);
  const [show, setShow] = useState(0);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const rows = [
    {
      label: "Id",
      field: "id",
    },
    {
      label: "Nombre Cliente",
      field: "nameClient",
    },
    {
      label: "Tipo de bien",
      field: "typeOfAsset",
    },
    {
      label: "Dirección",
      field: "address",
    },
    {
      label: "Rol",
      field: "rol",
    },
    {
      label: "Valoración UF",
      field: "valueUf",
    },
    {
      label: "Valoración en pesos",
      field: "valueP",
    },
    {
      label: "Calidad valoración",
      field: "valueUfReport",
    },
    {
      label: "Acción",
      field: "actionBtn",
    },
  ];
  const [datatable, setDatatable] = useState({
    columns: rows,
    rows: [],
  });
  useEffect(() => {
    fetchClient();
    const interval = setInterval(() => {
      setCounter((state) => {
        if (state > 0) {
          return state - 1;
        }
      });
      return () => clearInterval(interval);
    }, 1000);
  }, []);
  const downloadDoc = async (id) => {
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .get(APIRoute.doc + "/" + id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setShow(1)
        if (data.length === 0) {
          Swal.fire("Alerta", "Esta tasación no posee PDF visado", "error");
        } else {
          for (let i = 0; i <= data.length; i++) {
            if (data[i].id_archive_type === 1) {
              openInNewTab(APIRoute.url + data[i].name_file);
            }
          }
        }
      })
      .catch(() => {setShow(1)});
  };
  const uploadDoc = async (id) => {
    const { value: file } = await Swal.fire({
      title: "Ingrese PDF (máx 5 MB) ",
      input: "file",
      inputAttributes: {
        accept: ".pdf",
      },
    });
    if (file.size >= 5230000) {
      Swal.fire("PDF excede el tamaño máximo", "", "error");
    } else {
      setShow(0);
      if (file) {
        let tokenStr = sessionStorage.getItem("token");
        const formData = new FormData();
        formData.append("id_appreciation", id);
        formData.append("id_archive_type", 1);
        formData.append("file", file);
        await axios
          .post(APIRoute.doc, formData, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              Authorization: `Bearer ${tokenStr}`,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            setShow(1)
            Swal.fire("PDF subido con éxito", "", "success");
            sendClient(id);
          })
          .catch(({ response }) => {
            setShow(1)
            if (response.status == 409) {
              Swal.fire(
                "Ya existe un documento PDF",
                "Esta valoración ya se encuentra visada.",
                "warning"
              );
            } else {
              Swal.fire("error al subir PDF", "", "error");
            }
          });
      } else {
        Swal.fire("Cambios no guardados", "", "warning");
      }
    }
  };
  const sendClient = async (id) => {
    let tokenStr = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("id_appreciation", id);
    await axios
      .post(APIRoute.send, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(() => {
        Swal.fire("Correo con pdf enviado a cliente con exito");
      })
      .catch(() => {
        Swal.fire("No se pudo enviar el correo al cliente");
      });
  };
  const fetchClient = async () => {
    await axios.get(APIRoute.appreciations).then(({ data }) => {
      let asset = [];
      let valorPesos = [];
      let valueUf = [];
      for (let j = 0; j < data.length; j++) {
        valorPesos[j] = data[j]["value_uf_report"] * data[j]["value_uf_saved"];
        valueUf[j] = data[j]["value_uf_report"];
        if (data[j].id_type_of_assets === 1) {
          asset[j] = "CASA";
        } else if (data[j].id_type_of_assets === 2) {
          asset[j] = "DEPARTAMENTO";
        }
      }
      setShow(1);
      setDatatable({
        columns: rows,
        rows: data.map((appreciation, index) => {
          return {
            id: appreciation.id_appreciation,
            nameClient: appreciation.name_client,
            typeOfAsset: asset[index],
            address: appreciation.address +" "+ appreciation.address_number,
            rol: appreciation.rol,
            valueUf: "UF"+" "+Math.floor(valueUf[index]).toLocaleString('es-CL'),
            valueP:' $'+" "+ Math.floor(valorPesos[index]).toLocaleString('es-CL'),
            valueUfReport: appreciation.quality+"/"+"7",
            actionBtn: (
              <>
                <Button
                  sx={{ my: 1 }}
                  title="Subir PDF"
                  color="success"
                  variant="contained"
                  onClick={() => (uploadDoc(appreciation.id_appreciation))}
                  style={{ width: "2rem", height: "3em" }}
                  disabled={appreciation.status ? 1 : 0}
                >
                  <RiUpload2Fill style={{ margin: 0, fontSize: "20px" }} />
                </Button>
                <Button
                  sx={{ p: 1 }}
                  spacing={2}
                  title="Descargar"
                  variant="contained"
                  color="warning"
                  onClick={() => (downloadDoc(appreciation.id_appreciation), setShow(0))}
                  diasbled={appreciation.status ? 0 : 1}
                >
                  <HiDocumentDownload
                    style={{ width: "2rem", height: "2em" }}
                  />
                </Button>
              </>
            ),
          };
        }),
      });
    });
  };
  return (
    <Container sx={{ my: 5 }}>
      <Typography>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5">Listado de valoraciones</Typography>
          {show ? (
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <MDBDataTableV5
                hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                searchTop
                searchBottom={false}
                searchLabel="Busqueda general"
                exportToCSV
                responsive
                data={datatable}
              />
            </Paper>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Typography>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import APIRoute from "../routersAPI/routes.json";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  FormGroup,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  Box,
  Typography,
  Paper,
  Container,
  FormHelperText,
  Alert,
  CircularProgress,
} from "@mui/material";
import GoogleMaps from "./map";
import { IoIosArrowBack } from "react-icons/io";

export default function CreateClient() {
  const navigate = useNavigate();
  let [status, setStatus] = useState(false);
  const [rutClient, setRutClient] = useState("");
  const [rutError, setRutError] = useState("");
  const [nameClient, setNameClient] = useState("");
  const [nameClientError, setNameClientError] = useState(false);
  const [emailClient, setEmailClient] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [phoneClient, setPhoneClient] = useState("");
  const [phoneValid, setPhoneValid] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [idAdministrator] = useState(sessionStorage.getItem("idUser"));
  const [typeOfAsset, setTypeOfAsset] = useState("");
  const [typeOfAssetList, setTypeOfAssetList] = useState([]);
  const [region, setRegion] = useState("");
  const [commune, setCommune] = useState("");
  const [communeList, setCommuneList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [supervisor, setSupervisor] = useState("");
  const [supervisorList, setSupervisorList] = useState([]);
  const [rol, setRol] = useState("");
  const [rolError, setRolError] = useState(false);
  const [terrainArea, setTerrainArea] = useState("");
  const [terrainAreaError, setTerrainAreaError] = useState(false);
  const [constructionArea, setConstructionArea] = useState("");
  const [constructionAreaError, setConstructionAreaError] = useState(false);
  const [bedroom, setBedroom] = useState("");
  const [bedroomError, setBedroomError] = useState(false);
  const [bathroom, setBathroom] = useState("");
  const [bathroomError, setBathroomError] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [loadingCommune, setLoadingCommune] = useState(false);

  useEffect(() => {
    fetchRegionList();
    fetchTypeOfAssetList();
    selectSupervisor();
  }, []);

  const fetchRegionList = async () => {
    let tokenStr = sessionStorage.getItem("token");
    axios
      .get(APIRoute.region, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setRegionList(data);
      });
  };
  const fetchTypeOfAssetList = async () => {
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .get(APIRoute.asset, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setTypeOfAssetList(data);
      });
  };
  const selectSupervisor = async () => {
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .get(APIRoute.supervisor, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setSupervisorList(data);
      });
  };
  const handleCommune = async (commune) => {
    setLoadingCommune(true);
    const formData = new FormData();
    formData.append("id", commune);
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.commune, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setCommuneList(data);
        setLoadingCommune(false);
      })
      .catch(({ response }) => {
        setLoadingCommune(false);
        if (response.status === 500) {
          setErrorMessage(response.data.errors);
        }
      });
  };
  const createClient = async () => {
    let timerInterval;
    setStatus(true);
    Swal.fire({
      position: "center",
      title: "Cargando datos",
      timer: 7000,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
    const formData = new FormData();
    formData.append("rut", rutClient);
    formData.append("name", nameClient);
    formData.append("phone", phoneClient);
    formData.append("email", emailClient);
    formData.append("type", "client");
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.createClient, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setStatus(false);
        const idClient = data["id_client"];
        createAccessKey(idClient);
        Swal.fire({
          position: "center",
          title: "Revisando integridad de los datos",
          timer: 7000,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      })
      .catch(({ response }) => {
        setStatus(false);
        if (response.status) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            title: "Error al crear cliente",
            icon: "error",
          });
        }
      })
      .finally(() => {});
  };

  const createAccessKey = async (idClient) => {
    setStatus(true);
    let timerInterval;
    const formData = new FormData();
    formData.append("idAdministrator", idAdministrator);
    let tokenStr = sessionStorage.getItem("token");
    axios
      .post(APIRoute.code, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setStatus(false);
        const geocoder = new window.google.maps.Geocoder();
        getvalues(data.access_code["id_access_code"], idClient, geocoder);
        Swal.fire({
          position: "center",
          title: "Recopilando información",
          timer: 26000,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      })
      .catch(({ response }) => {
        setStatus(false);
        if (response) {
          Swal.fire({
            title: "Error creando código cliente",
            icon: "error",
          });
        }
      })
      .finally(() => {});
  };
  const geocodePlaceId = async (geocoder) => {
    const placeId = sessionStorage.getItem("placeid");
    await geocoder
      .geocode({ placeId: placeId })
      .then(({ results }) => {
        sessionStorage.setItem("latitude", results[0].geometry.location.lat());
        sessionStorage.setItem("longitude", results[0].geometry.location.lng());
      })
      .catch((e) => {});
  };
  const getvalues = async (accessCode, idClient, geocoder) => {
    await geocodePlaceId(geocoder);
    if (bathroom >= 1) {
      setBathroom(0);
    }
    if (bedroom >= 1) {
      setBedroom(0);
    }
    createAppreciation(accessCode, idClient);
  };
  const createAppreciation = async (accessCode, idClient) => {
    setStatus(true);
    const formData = new FormData();
    formData.append("id_client", idClient);
    formData.append("id_administrator", idAdministrator);
    formData.append("id_type_of_assets", typeOfAsset);
    formData.append("id_access_code", accessCode);
    formData.append("id_commune", commune);
    formData.append("address", sessionStorage.getItem("address"));
    formData.append("address_number", sessionStorage.getItem("addressNumber"));
    formData.append("rol", rol);
    formData.append("terrain_area", terrainArea);
    formData.append("construction_area", constructionArea);
    formData.append("bedrooms", bedroom);
    formData.append("bathrooms", bathroom);
    formData.append("latitude", sessionStorage.getItem("latitude"));
    formData.append("longitude", sessionStorage.getItem("longitude"));

    let tokenStr = sessionStorage.getItem("token");
    let timerInterval;
    await axios
      .post(APIRoute.appreciation, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        sendEmail(data.id_appreciation["id_appreciation"], data.valo, data.wit);
        setStatus(false);
        Swal.fire({
          position: "center",
          title: "Realizando cálculos",
          timer: 20000,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      })
      .catch(({ response }) => {
        setStatus(false);
        if (response) {
          setErrorMessage(response.data.errors);
          Swal.fire({
            title: "Error en datos valoración",
            text: "Por favor, revise si los campos están ingresados correctamente.",
            icon: "error",
          });
        }
      })
      .finally(() => {
      });
  };
  const sendEmail = async (id_appreciation, valo, wit) => {
    const formData = new FormData();
    formData.append("id_appreciation", id_appreciation);
    formData.append("email_supervisor", supervisor);
    const valoJson = JSON.stringify(valo);
    formData.append("valo", valoJson);
    console.log(valoJson);
    const witJson = JSON.stringify(wit);
    formData.append("wit", witJson);
    console.log(witJson);
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.sendExcel, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
          "content-type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then(({ data }) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Valoración creada exitosamente",
          showConfirmButton: true,
          timer: 3000,
        });
        sessionStorage.removeItem("address");
        sessionStorage.removeItem("addressNumber");
        sessionStorage.removeItem("latitude");
        sessionStorage.removeItem("longitude");
        navigate("/");
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
          Swal.fire({
            title: "Error al crear o enviar excel",
            icon: "error",
          });
        }
      });
  };
  const validateRut = (rut) => {
    const rutRegex = /^[0-9]+-[0-9kK]{1}$/;
    if (!rut) {
      return "Rut requerido";
    } else if (!rutRegex.test(rut)) {
      return "Rut inválido";
    } else {
      const [rutNumber, rutDv] = rut.split("-");
      const rutDigits = Array.from(rutNumber, Number);
      const dvResult = rutDigits
        .reverse()
        .reduce((accum, current, i) => accum + current * ((i % 6) + 2), 0);
      const calculatedDv = 11 - (dvResult % 11);
      if (calculatedDv === 11) {
        return rutDv.toUpperCase() !== "0" ? "Rut inválido" : undefined;
      } else if (calculatedDv === 10) {
        return rutDv.toUpperCase() !== "K" ? "Rut inválido" : undefined;
      } else {
        return calculatedDv.toString() === rutDv.toLowerCase()
          ? undefined
          : "Rut inválido";
      }
    }
  };
  const validateEmail = (email) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmailClient(email);
    setEmailValid(validateEmail(email));
  };
  const validatePhone = (phone) => {
    const phoneRegex = /^[98765432]\d{8}$/;
    return phoneRegex.test(phone);
  };
  const handlePhoneChange = (event) => {
    const phone = event.target.value;
    setPhoneClient(phone);
    setPhoneValid(validatePhone(phone));
  };
  const handleNameClientChange = (event) => {
    setNameClient(event.target.value);

    if (!event.target.value.trim()) {
      setNameClientError(true);
    } else {
      setNameClientError(false);
    }
  };

  const handleRolChange = (event) => {
    setRol(event.target.value);

    if (!event.target.value.trim()) {
      setRolError(true);
    } else {
      setRolError(false);
    }
  };
  const handleTerrainAreaChange = (event) => {
    setTerrainArea(event.target.value);

    if (!event.target.value.trim()) {
      setTerrainAreaError(true);
    } else {
      setTerrainAreaError(false);
    }
  };
  const handleConstructionAreaChange = (event) => {
    setConstructionArea(event.target.value);

    if (!event.target.value.trim()) {
      setConstructionAreaError(true);
    } else {
      setConstructionAreaError(false);
    }
  };
  const handleBedroomChange = (event) => {
    setBedroom(event.target.value);

    if (!event.target.value.trim()) {
      setBedroomError(true);
    } else {
      setBedroomError(false);
    }
  };
  const handleBathroomChange = (event) => {
    setBathroom(event.target.value);

    if (!event.target.value.trim()) {
      setBathroomError(true);
    } else {
      setBathroomError(false);
    }
  };

  return (
    <Box component="main" sx={{ p: 1, my: 2 }}>
      <Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Container>
                <Grid
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  container
                >
                  <Typography
                    className="text-center align-items-center"
                    variant="h6"
                  >
                    Ingresar datos del bien
                  </Typography>
                  <hr />
                  <FormGroup>
                    {Object.keys(validationError).length > 0 && (
                      <Grid direction="row">
                        <Alert variant="filled" severity="error">
                          {validationError}
                        </Alert>
                      </Grid>
                    )}
                    <Typography className="text-center align-items-center">
                      Rut cliente
                    </Typography>
                    <TextField
                      error={!!rutError}
                      helperText={rutError || ""}
                      sx={{ minWidth: 300, my: 2 }}
                      placeholder="Ej: 7654564-0"
                      value={rutClient}
                      required
                      onChange={(event) => {
                        const newRut = event.target.value;
                        setRutClient(newRut);
                        setRutError(validateRut(newRut));
                      }}
                    />
                    <Typography className="text-center align-items-center">
                      Nombre cliente
                    </Typography>
                    <TextField
                      error={nameClientError}
                      helperText={nameClientError ? "Nombre requerido" : ""}
                      sx={{ minWidth: 300, my: 2 }}
                      placeholder="Ej: Juan Pérez"
                      value={nameClient}
                      onChange={handleNameClientChange}
                      required
                    />
                    <Typography className="text-center align-items-center">
                      Correo cliente
                    </Typography>
                    <TextField
                      error={!emailValid && emailClient.length > 0}
                      helperText={
                        !emailValid && emailClient.length > 0
                          ? "Correo no válido"
                          : ""
                      }
                      sx={{ minWidth: 300, my: 2 }}
                      placeholder="Ej: juanperez@gmail.com"
                      value={emailClient}
                      required
                      onChange={handleEmailChange}
                    />
                    <Typography className="text-center align-items-center">
                      Teléfono cliente
                    </Typography>
                    <Grid sx={{ my: 2 }}>
                      <TextField
                        sx={{ width: 60, mx: 2 }}
                        disabled
                        value="+ 56"
                      ></TextField>
                      <TextField
                        error={!phoneValid && phoneClient.length > 0}
                        helperText={
                          !phoneValid && phoneClient.length > 0
                            ? "Teléfono no válido"
                            : ""
                        }
                        sx={{ minWidth: 150 }}
                        placeholder="Ej: 98787678"
                        value={phoneClient}
                        required
                        onChange={handlePhoneChange}
                      />
                    </Grid>
                    <Grid
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      container
                    >
                      <Typography>Seleccione Región</Typography>
                      <Select
                        error={region.length === 0 ? true : false}
                        helperText={!region.length ? "Región requerido" : ""}
                        value={region}
                        onChange={(event) => {
                          setRegion(event.target.value);
                          handleCommune(event.target.value);
                        }}
                        required
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          <em>Seleccione región</em>
                        </MenuItem>

                        {regionList.map((item, index) => (
                          <MenuItem key={index} value={item.id_region}>
                            {item.name_region}
                          </MenuItem>
                        ))}
                      </Select>
                      {loadingCommune ? (
                        <Grid sx={{ my: 3 }}>
                          Cargando Comunas...
                          <CircularProgress
                            sx={{ mx: 2 }}
                            style={{ width: "1rem", height: "1em" }}
                          />
                        </Grid>
                      ) : (
                        <>
                          <Typography id="demo-simple-select-label">
                            Seleccione Comuna
                          </Typography>
                          <Select
                            error={commune.length === 0 ? true : false}
                            helperText={
                              !commune.length ? "Comuna requerido" : ""
                            }
                            value={commune}
                            onChange={(event) => {
                              setCommune(event.target.value);
                            }}
                            required
                            label="Comuna"
                            sx={{ minWidth: 300, my: 2 }}
                          >
                            <MenuItem value="" defaultValue disabled="disabled">
                              Seleccione comuna
                            </MenuItem>
                            {communeList.map((item, index) => (
                              <MenuItem key={index} value={item.id_commune}>
                                {item.name_commune}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}

                      <Typography id="demo-simple-select-label">
                        Seleccione Tipo de bien
                      </Typography>
                      <Select
                        error={typeOfAsset.length === 0 ? true : false}
                        helperText={
                          !typeOfAsset.length ? "Tipo de bien requerido" : ""
                        }
                        value={typeOfAsset}
                        onChange={(event) => {
                          setTypeOfAsset(event.target.value);
                        }}
                        required
                        label="Tipo de bien"
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          Seleccione tipo de bien
                        </MenuItem>
                        {typeOfAssetList.map((item, index) => (
                          <MenuItem key={index} value={item.id_type_of_assets}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>
                        Ingrese dirección y número del bien
                      </Typography>
                      <GoogleMaps />
                      <Typography>Rol</Typography>
                      <TextField
                        error={rolError}
                        helperText={
                          rolError ? "El rol es requerido" : ""
                        }
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese rol del bien"
                        value={rol}
                        onChange={handleRolChange}
                        required
                      />
                      <Typography>Área terreno</Typography>
                      <TextField
                        error={terrainAreaError}
                        helperText={
                          terrainAreaError ? "Área terreno requerido" : ""
                        }
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese área terreno"
                        type="number"
                        value={terrainArea}
                        required
                        onChange={handleTerrainAreaChange}
                      />
                      <Typography>Área en construcción</Typography>
                      <TextField
                        error={constructionAreaError}
                        helperText={
                          constructionAreaError
                            ? "Área en construcción requerido"
                            : ""
                        }
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese área en construcción del bien"
                        type="number"
                        value={constructionArea}
                        required
                        onChange={handleConstructionAreaChange}
                      />
                      <Typography>Habitación</Typography>
                      <TextField
                        error={bedroomError}
                        helperText={
                          bedroomError ? "N° habitación requerido" : ""
                        }
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese n° habitaciones del bien"
                        type="number"
                        value={bedroom}
                        required
                        onChange={handleBedroomChange}
                      />
                      <Typography>Baño</Typography>
                      <TextField
                        error={bathroomError}
                        helperText={bathroomError ? "N° baño requerido" : ""}
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese n° baños del bien"
                        type="number"
                        value={bathroom}
                        required
                        onChange={handleBathroomChange}
                      />
                    </Grid>
                    <Typography id="demo-simple-select-label">
                      Seleccione Supervisor
                    </Typography>
                    <Select
                      error={supervisor.length === 0 ? true : false}
                      helperText={
                        !supervisor.length ? "Supervisor requerido" : ""
                      }
                      value={supervisor}
                      onChange={(event) => {
                        setSupervisor(event.target.value);
                      }}
                      required
                      sx={{ minWidth: 300, my: 2 }}
                    >
                      <MenuItem value="" selected disabled>
                        Seleccione supervisor
                      </MenuItem>
                      {supervisorList.map((item, index) => (
                        <MenuItem key={index} value={item.email}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Grid>
                      <Link to={"/"} color="success">
                        <Button
                          type="submit"
                          sx={{ m: 2 }}
                          variant="contained"
                          title="Volver"
                        >
                          <Typography variant="h6">
                            <IoIosArrowBack style={{ fontSize: "25px" }} />
                            <Typography sx={{ mx: 1 }} variant>
                              Volver
                            </Typography>
                          </Typography>
                        </Button>
                      </Link>
                      <Button
                        title="Ingresar"
                        variant="contained"
                        color="success"
                        onClick={() => createClient()}
                        disabled={status}
                      >
                        <Typography variant="h6">Ingresar</Typography>
                      </Button>
                    </Grid>
                  </FormGroup>
                </Grid>
              </Container>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Container sx={{ minWidth: 500, my: 3 }}>
                <Typography
                  className="text-center align-items-center"
                  variant="h6"
                >
                  Ingresar datos del bien
                </Typography>
                <hr />
                <FormGroup>
                  <Grid
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    container
                  >
                    <Typography>Rut cliente</Typography>
                    <FormHelperText sx={{ my: 1 }}>12342342-K</FormHelperText>
                    <TextField
                      error={!!rutError}
                      helperText={rutError || ""}
                      sx={{ minWidth: 350, my: 2 }}
                      placeholder="Ej: 7654564-0"
                      value={rutClient}
                      required
                      onChange={(event) => {
                        const newRut = event.target.value;
                        setRutClient(newRut);
                        setRutError(validateRut(newRut));
                      }}
                    />
                    <Typography>Nombre cliente</Typography>
                    <TextField
                      error={nameClientError}
                      helperText={nameClientError ? "Nombre requerido" : ""}
                      sx={{ minWidth: 350, my: 2 }}
                      placeholder="Ej: Juan Pérez"
                      value={nameClient}
                      onChange={handleNameClientChange}
                      required
                    />
                    <Typography>Correo cliente</Typography>
                    <TextField
                      error={!emailValid && emailClient.length > 0}
                      helperText={
                        !emailValid && emailClient.length > 0
                          ? "Correo no válido"
                          : ""
                      }
                      sx={{ minWidth: 350, my: 2 }}
                      placeholder="Ej: juanperez@gmail.com"
                      value={emailClient}
                      required
                      onChange={handleEmailChange}
                    />
                    <Typography>Teléfono cliente</Typography>
                    <Grid sx={{ my: 2 }}>
                      <TextField
                        sx={{ width: 90, mx: 1 }}
                        disabled
                        value="+ 56"
                      ></TextField>
                      <TextField
                        error={!phoneValid && phoneClient.length > 0}
                        helperText={
                          !phoneValid && phoneClient.length > 0
                            ? "Teléfono no válido"
                            : ""
                        }
                        sx={{ minWidth: 245 }}
                        placeholder="Ej: 987876788"
                        value={phoneClient}
                        required
                        onChange={handlePhoneChange}
                      />
                    </Grid>
                    <Grid
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      container
                    >
                      <Typography>Seleccione Región</Typography>
                      <Select
                        error={region.length === 0 ? true : false}
                        helperText={!region.length ? "Región requerido" : ""}
                        value={region}
                        onChange={(event) => {
                          setRegion(event.target.value);
                          handleCommune(event.target.value);
                        }}
                        required
                        sx={{ minWidth: 350, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          <em>Seleccione región</em>
                        </MenuItem>
                        {regionList.map((item, index) => (
                          <MenuItem key={index} value={item.id_region}>
                            {item.name_region}
                          </MenuItem>
                        ))}
                      </Select>
                      {loadingCommune ? (
                        <Grid sx={{ my: 3 }}>
                          Cargando Comunas...
                          <CircularProgress
                            sx={{ mx: 2 }}
                            style={{ width: "1rem", height: "1em" }}
                          />
                        </Grid>
                      ) : (
                        <>
                          <Typography id="demo-simple-select-label">
                            Seleccione Comuna
                          </Typography>
                          <Select
                            error={commune.length === 0 ? true : false}
                            helperText={
                              !commune.length ? "Comuna requerido" : ""
                            }
                            value={commune}
                            onChange={(event) => {
                              setCommune(event.target.value);
                            }}
                            required
                            label="Comuna"
                            sx={{ minWidth: 350, my: 2 }}
                          >
                            <MenuItem value="" defaultValue disabled="disabled">
                              Seleccione comuna
                            </MenuItem>
                            {communeList.map((item, index) => (
                              <MenuItem key={index} value={item.id_commune}>
                                {item.name_commune}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                      <Typography id="demo-simple-select-label">
                        Seleccione Tipo de bien
                      </Typography>
                      <Select
                        error={!typeOfAsset}
                        helperText={
                          !typeOfAsset ? " Dirección y número requerido" : ""
                        }
                        value={typeOfAsset}
                        onChange={(event) => {
                          setTypeOfAsset(event.target.value);
                        }}
                        required
                        label="Tipo de bien"
                        sx={{ minWidth: 350, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          Seleccione tipo de bien
                        </MenuItem>
                        {typeOfAssetList.map((item, index) => (
                          <MenuItem key={index} value={item.id_type_of_assets}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>
                        Ingrese dirección y número del bien
                      </Typography>
                      <GoogleMaps />
                      <Typography>Rol</Typography>
                      <TextField
                        error={rolError}
                        helperText={
                          rolError ? "El rol es requerido" : ""
                        }
                        sx={{ minWidth: 350, my: 2 }}
                        placeholder="Ingrese rol del bien"
                        value={rol}
                        onChange={handleRolChange}
                        required
                      />
                      <Typography>Área terreno</Typography>
                      <TextField
                        error={terrainAreaError}
                        helperText={
                          terrainAreaError ? "Área terreno requerido" : ""
                        }
                        sx={{ minWidth: 350, my: 2 }}
                        placeholder="Ingrese área terreno"
                        type="number"
                        value={terrainArea}
                        required
                        onChange={handleTerrainAreaChange}
                      />
                      <Typography>Área en construcción</Typography>
                      <TextField
                        error={constructionAreaError}
                        helperText={
                          constructionAreaError
                            ? "Área en construcción requerido"
                            : ""
                        }
                        sx={{ minWidth: 350, my: 2 }}
                        placeholder="Ingrese área en construcción del bien"
                        type="number"
                        value={constructionArea}
                        required
                        onChange={handleConstructionAreaChange}
                      />
                      <Typography>Habitación</Typography>
                      <TextField
                        error={bedroomError}
                        helperText={
                          bedroomError ? "N° habitación requerido" : ""
                        }
                        sx={{ minWidth: 350, my: 2 }}
                        placeholder="Ingrese n° habitaciones del bien"
                        type="number"
                        value={bedroom}
                        required
                        onChange={handleBedroomChange}
                      />
                      <Typography>Baño</Typography>
                      <TextField
                        error={bathroomError}
                        helperText={bathroomError ? "N° baño requerido" : ""}
                        sx={{ minWidth: 350, my: 2 }}
                        placeholder="Ingrese n° baños del bien"
                        type="number"
                        value={bathroom}
                        required
                        onChange={handleBathroomChange}
                      />
                    </Grid>
                    <Typography id="demo-simple-select-label">
                      Seleccione Supervisor
                    </Typography>
                    <Select
                      error={supervisor.length === 0 ? true : false}
                      helperText={
                        !supervisor.length ? "Supervisor requerido" : ""
                      }
                      value={supervisor}
                      onChange={(event) => {
                        setSupervisor(event.target.value);
                      }}
                      required
                      sx={{ minWidth: 350, my: 2 }}
                    >
                      <MenuItem value="" selected disabled>
                        Seleccione supervisor
                      </MenuItem>
                      {supervisorList.map((item, index) => (
                        <MenuItem key={index} value={item.email}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Grid>
                      <Link to={"/"} color="success">
                        <Button
                          type="submit"
                          sx={{ m: 3 }}
                          variant="contained"
                          title="Volver"
                        >
                          <Typography variant="h6">
                            <IoIosArrowBack style={{ fontSize: "25px" }} />
                            <Typography sx={{ mx: 1 }} variant>
                              Volver
                            </Typography>
                          </Typography>
                        </Button>
                      </Link>
                      {status ? (
                        <Button
                          title="Ingresar"
                          variant="contained"
                          color="success"
                          onClick={() => createClient()}
                          disabled={status}
                        >
                          <Typography variant="h6">Ingresar</Typography>
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          title="Ingresar"
                          variant="contained"
                          color="success"
                          onClick={() => createClient()}
                        >
                          <Typography variant="h6">Ingresar</Typography>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </FormGroup>
              </Container>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
